body[data-theme='light'] {
  @import '../src/scss/light-slider.scss';

  .slider-tab {
    background-color: #d7caaa;
  }

  .contact-btn {
    border-color: #000 !important;
  }

  .contact-btn:hover {
    background-color: #000 !important;
    color: white !important;
  }

  .contact-email {
    color: black;
  }

  header .iconify.header-icon {
    color: black;
  }
}
